
import Layout from "../components/Layout";
import Liv from "../assets/imgs/liv.svg";
import trash from "../assets/imgs/fi_trash.svg";
import "../assets/css/panier.scss";
import ReturnIcon from "../assets/imgs/fi_arrow-left.svg";
import mapIcon from "../assets/imgs/fi_map-pin-orange.svg";
import Arrow from "../assets/imgs/keyboard_arrow_right_24px.svg";
import Logo from "../assets/imgs/LogoMarion.svg";
import PaymentCards from "../assets/imgs/payment-cards.svg";
import React, { useEffect, useState } from "react";
import Services from "../services/global.service";
import { Button, Collapse } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AddAddress from "../components/addAdr";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection

const Livraison = () => {
  const [cart, setCart] = useState();
  const [promo_result, setPromo_result] = useState([]);
  const [modalAdr, setModalAdr] = useState();
  const [openMyArticles, setOpenMyArticles] = useState(false);
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [myAddress, setMyAddress] = useState([]);
  const [tours, setTours] = useState([]);
  const [nextTour, setNextTour] = useState();
  const [groupedTours, setGroupedTours] = useState([]);
  const [selectedTour, setSelectedTour] = useState(
    localStorage.getItem("tour") || ""
  );
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate(); // For programmatic navigation

  const OpenAddModal = () => {
    setModalAdr(!modalAdr);
  };

  const getMyCart = () => {
    Services.getAll("panier")
      .then((res) => {
        setCart(res?.data?.panier);
        setPromo_result(res?.data?.promo_result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMyAdresses = () => {
    Services.getOne(localStorage.getItem("id"), "adresses")
      .then((res) => {
        const filteredAddresses = res.data.filter(
          (adr) => adr.type === 1 && adr.default === 1
        );
        if (filteredAddresses[0]) {
          setMyAddress(filteredAddresses[0]);
        } else {
          window.location.href = "/profil/5";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTours = () => {
    Services.getAll("tour-livraisons/active-tours")
      .then((res) => {
        setTours(res.data.tours);
        const sortedTours = res.data.tours
          .filter(
            (tour) =>
              tour.datetime_start && new Date(tour.datetime_end) > new Date()
          )
          .sort(
            (a, b) => new Date(a.datetime_start) - new Date(b.datetime_start)
          );

        const now = new Date();
        let nextTour = null;
        for (const tour of sortedTours) {
          const tourStartDate = new Date(tour.livraison_heur_debut);
          if (!nextTour || tourStartDate < nextTour) {
            nextTour = tour;
          }
        }

        const groupedTours = {};
        for (const tour of sortedTours) {
          if (tour !== nextTour) {
            const date = tour.livraison_heur_debut.split(" ")[0];
            if (!groupedTours[date]) {
              groupedTours[date] = [];
            }
            groupedTours[date].push(tour);
          }
        }

        setNextTour(nextTour);
        if (selectedTour === "") {
          setSelectedTour(nextTour?.id);
        }
        if (
          localStorage.getItem("tour") === null ||
          localStorage.getItem("tour") === "" ||
          localStorage.getItem("tour") === undefined
        ) {
          if (nextTour) {
            localStorage.setItem("tour", nextTour?.id);
          }
        }
        setGroupedTours(groupedTours);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Function to create commande directly for free orders
  const createFreeCommande = async () => {
    setIsLoading(true);
    try {
      const order = {
        id_panier: cart?.id,
        id_tourlivraison: selectedTour,
        id_adresse_user: myAddress?.id,
        id_user: localStorage.getItem("id"), // Add user ID as in PaymentComp
        montant: 0,
        ttc: 0,
        ht: 0,
        tva: "default",
        status: 1, // Initial status
        verification_code: Math.floor(1000 + Math.random() * 9000).toString(),
        date_commande: new Date().toISOString(),
      };

      const response = await Services.create(order, "commandes/add"); // Use the same endpoint as PaymentComp
      if (response.status === 201 || response.status === 200) {
        navigate("/validation"); // Redirect to same success page as PaymentComp
      } else {
        throw new Error(`Failed to create commande. Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error creating free commande:", error);
      alert("Une erreur est survenue lors de la création de la commande.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentClick = (e) => {
    e.preventDefault();
    if (!selectedTour) {
      return; // Do nothing if no tour is selected
    }

    // Check if totalHT and tva20 are 0 in promo_result
    if (
      promo_result?.totalHT === 0 &&
      promo_result?.tva20 === 0 &&
      promo_result?.discount > 0 // Ensure promo_result is valid
    ) {
      createFreeCommande(); // Create commande directly and redirect
    } else {
      navigate("/paiement"); // Go to payment page
    }
  };

  const formatDateTimeRange = (startDateStr, endDateStr) => {
    const options = { weekday: "long", month: "long", day: "numeric" };
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    const formattedStartDate = new Intl.DateTimeFormat("fr-FR", options).format(
      startDate
    );
    const formattedEndDate = new Intl.DateTimeFormat("fr-FR", options).format(
      endDate
    );

    const startHours = startDate.getHours().toString().padStart(2, "0");
    const startMinutes = startDate.getMinutes().toString().padStart(2, "0");
    const formattedStartTime = `${startHours}:${startMinutes}`;

    const endHours = endDate.getHours().toString().padStart(2, "0");
    const endMinutes = endDate.getMinutes().toString().padStart(2, "0");
    const formattedEndTime = `${endHours}:${endMinutes}`;

    const formattedStartDateCapitalized =
      formattedStartDate.charAt(0).toUpperCase() + formattedStartDate.slice(1);

    return `${formattedStartDateCapitalized} entre ${formattedStartTime} - ${formattedEndTime}`;
  };

  const formatTimeRange = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    const startHours = startDate.getHours().toString().padStart(2, "0");
    const startMinutes = startDate.getMinutes().toString().padStart(2, "0");
    const formattedStartTime = `${startHours}:${startMinutes}`;

    const endHours = endDate.getHours().toString().padStart(2, "0");
    const endMinutes = endDate.getMinutes().toString().padStart(2, "0");
    const formattedEndTime = `${endHours}:${endMinutes}`;

    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { weekday: "long", day: "numeric", month: "long" };
    const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(
      date
    );
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  };

  const handleTourSelection = (tourId) => {
    setSelectedTour(tourId);
    localStorage.setItem("tour", tourId);
  };

  useEffect(() => {
    getMyCart();
    getMyAdresses();
    getTours();
  }, [modalAdr]);

  return (
    <Layout header={false}>
      {modalAdr && (
        <AddAddress modalAdr setModalAdr={setModalAdr} panier={true} />
      )}
      <header className="d-flex header text-start align-items-center">
        <div className="col-auto">
          <a href="/">
            <img src={Logo} alt="logo marion" className="" />
          </a>
        </div>
        <div className="col d-flex justify-content-around">
          <div className="d-flex align-items-center">
            <h6 className="prog-circul" style={{ width: 66, height: 66 }}>
              <CircularProgressbar
                value={100}
                text={`1`}
                styles={buildStyles({
                  rotation: 0.25,
                  strokeLinecap: "round",
                  textSize: "24px",
                  textStyles: {
                    fill: "#00264A",
                    fontWeight: "700",
                    fontFamily: "Outfit-Bold",
                  },
                  pathTransitionDuration: 0.5,
                  pathColor: `rgba(255, 121, 113, 1)`,
                  textColor: "#00264A",
                  trailColor: "#FFF4F3",
                  backgroundColor: "#FF7971",
                })}
              />
            </h6>
            <span className="subtitle-m bleu700 pl-16">Panier</span>
          </div>
          <div className="d-flex align-items-center">
            <h6 className="prog-circul" style={{ width: 66, height: 66 }}>
              <CircularProgressbar
                value={70}
                text={`2`}
                styles={buildStyles({
                  rotation: 0.03,
                  strokeLinecap: "round",
                  textSize: "24px",
                  textStyles: {
                    fill: "#00264A",
                    fontWeight: "700",
                    fontFamily: "Outfit-Bold",
                  },
                  pathTransitionDuration: 0.5,
                  pathColor: `rgba(255, 121, 113, 1)`,
                  textColor: "#00264A",
                  trailColor: "#FFF4F3",
                  backgroundColor: "#FF7971",
                })}
              />
            </h6>
            <span className="subtitle-m bleu700 pl-16">Livraison</span>
          </div>
          <div className="d-flex align-items-center">
            <h6 className="prog-circul" style={{ width: 66, height: 66 }}>
              <CircularProgressbar
                value={0}
                text={`3`}
                styles={buildStyles({
                  rotation: 0.25,
                  strokeLinecap: "round",
                  textSize: "24px",
                  textStyles: {
                    fill: "#00264A",
                    fontWeight: "700",
                    fontFamily: "Outfit-Bold",
                  },
                  pathTransitionDuration: 0.5,
                  pathColor: `rgba(255, 121, 113, 1)`,
                  textColor: "#00264A",
                  trailColor: "#FFF4F3",
                  backgroundColor: "#FF7971",
                })}
              />
            </h6>
            <span className="subtitle-m bleu700 pl-16">Paiement</span>
          </div>
        </div>
      </header>
      {cart?.id ? (
        <div className="container pb-32">
          <h4 className="bleu800 pt-16 d-flex align-items-center">
            <a href="/panier">
              <img src={ReturnIcon} alt="Icon retour" className="mr-8" />
            </a>
            Livraison
          </h4>
          <div className="row mt-32">
            <div className="col-md-8">
              <div className="white-bg-card">
                <div className="d-flex justify-content-between">
                  <h6 className="bleu700">Adresse de livraison</h6>
                  <button className="link-s" onClick={OpenAddModal}>
                    Modifier
                  </button>
                </div>
                <div className="d-flex">
                  <div>
                    <img src={mapIcon} alt="map icon" className="mr-8" />
                  </div>
                  <div>
                    <p className="subtitle-s bleu700 py-0">
                      {myAddress?.nom} {myAddress?.prenom}
                    </p>
                    <p className="bleu600-body-s py-0">{myAddress?.adresse}</p>
                    <p className="bleu600-body-s py-0">
                      {myAddress?.telephone}
                    </p>
                  </div>
                </div>
              </div>
              <div className="white-bg-card mt-16">
                <h6 className="bleu700">Planification de la livraison</h6>
                <p className="subtitle-m bleu500">
                  Sélectionne l’heure de livraison souhaitée
                </p>
                <div className="grey-border mt-24">
                  {nextTour && (
                    <div key={nextTour.id} className="form-check d-flex">
                      <input
                        type="radio"
                        id={`tour-${nextTour.id}`}
                        name="tour-time"
                        value={nextTour.id}
                        className="form-check-input mr-8"
                        checked={nextTour.id == selectedTour}
                        defaultChecked
                        onChange={(e) => handleTourSelection(nextTour.id)}
                      />
                      <label
                        htmlFor={`tour-${nextTour.id}`}
                        className="form-check-label bleu700-body-s"
                      >
                        <p className="Exergue">Le plus tôt possible</p>
                        {formatDateTimeRange(
                          nextTour?.livraison_heur_debut,
                          nextTour?.livraison_heur_fin
                        )}
                      </label>
                    </div>
                  )}
                </div>
                {Object.keys(groupedTours).map((date) => (
                  <div className="grey-border mt-24">
                    <p className="Exergue">{formatDate(date)}</p>
                    {groupedTours[date].map((tour) => (
                      <div key={tour?.id} className="form-check d-flex">
                        <input
                          type="radio"
                          id={`tour-${tour?.id}`}
                          name="tour-time"
                          value={tour?.id}
                          className="form-check-input mr-8"
                          checked={tour.id == selectedTour}
                          onChange={(e) => handleTourSelection(tour.id)}
                        />
                        <label
                          htmlFor={`tour-${tour?.id}`}
                          className="form-check-label bleu700-body-s"
                        >
                          {formatTimeRange(
                            tour?.livraison_heur_debut,
                            tour?.livraison_heur_fin
                          )}
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
                <p className="legend mt-24">
                  Tu souhaites un autre créneau ? Contacte-nous au 06 69 67 65
                  05
                </p>
              </div>
              <div className="myArticles white-bg-card mt-24">
                <div className="row">
                  <div className="col-md-10">
                    <h6>Mes articles</h6>
                  </div>
                  <div className="col-md-2 text-end">
                    <Button
                      className="link-s"
                      onClick={() => setOpenMyArticles(!openMyArticles)}
                      aria-controls="my-articles"
                      aria-expanded={openMyArticles}
                    >
                      <img src={Arrow} alt="arrow icon" />
                    </Button>
                  </div>
                </div>
                <Collapse in={openMyArticles}>
                  <div id="my-articles">
                    {cart && cart?.panier_items
                      ? cart?.panier_items.map((item, index) => (
                          <div
                            className={`mt-16 ${
                              index > 0 ? "border-top-marion" : ""
                            }`}
                            key={item.id}
                          >
                            <div className="mt-8">
                              <div className="row my-32">
                                <div
                                  className="col-4 img-toggle"
                                  style={{
                                    backgroundImage: `url(${
                                      IMG_URL +
                                      item?.product_panier_item?.images[0]?.path
                                    })`,
                                  }}
                                ></div>
                                <div className="col-8">
                                  <p className="bleu600-body-s">
                                    {
                                      item?.product_panier_item
                                        ?.product_marque?.name
                                    }
                                  </p>
                                  <a
                                    href={`/product/${item?.product_panier_item?.id}`}
                                  >
                                    <p className="product-title">
                                      {item?.product_panier_item?.titre}
                                    </p>
                                  </a>
                                  <p className="legende">
                                    Ref:{item?.product_panier_item?.reference}
                                  </p>
                                  <p className="body-m bleu800">
                                    {(
                                      item?.product_panier_item?.ttc *
                                      +item?.quantity
                                    )
                                      ?.toFixed(2)
                                      .replace(".", ",")}{" "}
                                    € TTC
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </Collapse>
              </div>
            </div>
            <div className="col-md-4">
              <div className="white-bg-card">
                <h6>Récapitulatif</h6>
                 {/* {promo_result?.totalHT === 0 &&
                promo_result?.totalTTC === 0 &&
                promo_result?.tva20 === 0 ? ( */}
                { promo_result?.discount > 0 && promo_result?.frais_de_port_gratuits === "OUI"? (
                  <>
                    <div className="d-flex mt-32 justify-content-between">
                      <p className="bleu700-body-s">Total articles HT</p>
                      <p className="bleu700-body-s">
                        {cart?.totalHTProduits.toFixed(2).replace(".", ",")} €
                      </p>
                    </div>
                    <div className="d-flex mt-16 justify-content-between">
                      <p className="bleu700-body-s">Frais de livraison</p>
                      <p className="bleu700-body-s">
                        {cart?.FraisLivraison.toFixed(2).replace(".", ",") ?? 0}{" "}
                        €
                      </p>
                    </div>
                    {promo_result?.discount > 0 && promo_result && (
                      <div className="d-flex mt-16 justify-content-between">
                        <p className="bleu700-body-s">Réduction</p>
                        <p className="bleu700-body-s">
                          -{" "}
                          {promo_result?.discount?.toFixed(2).replace(".", ",")}{" "}
                          €
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="d-flex mt-32 justify-content-between">
                      <p className="bleu700-body-s">Total articles HT</p>
                      <p className="bleu700-body-s">
                        {cart?.totalHTProduits.toFixed(2).replace(".", ",")} €
                      </p>
                    </div>
                    {promo_result?.discount > 0 && promo_result && (
                      <div className="d-flex mt-16 justify-content-between">
                        <p className="bleu700-body-s">Réduction</p>
                        <p className="bleu700-body-s">
                          -{" "}
                          {promo_result?.discount?.toFixed(2).replace(".", ",")}{" "}
                          €
                        </p>
                      </div>
                    )}
                    <div className="d-flex mt-16 justify-content-between">
                      <p className="bleu700-body-s">Frais de livraison</p>
                      <p className="bleu700-body-s">
                        {cart?.FraisLivraison.toFixed(2).replace(".", ",") ?? 0}{" "}
                        €
                      </p>
                    </div>
                  </>
                )}
                <hr className="mt-16" />
                <div className="d-flex mt-16 justify-content-between">
                  <p className="Exergue">Total HT</p>
                  <p className="Exergue">
                    {promo_result?.discount > 0 && promo_result
                      ? promo_result?.totalHT?.toFixed(2).replace(".", ",")
                      : cart?.totalHT?.toFixed(2).replace(".", ",")}{" "}
                    €
                  </p>
                </div>
                <div className="d-flex mt-16 justify-content-between">
                  <p className="bleu700-body-s">TVA 20%</p>
                  <p className="bleu700-body-s">
                    {(promo_result?.discount > 0 && promo_result
                      ? promo_result.tva20
                      : cart?.tva20
                    )
                      .toFixed(2)
                      .replace(".", ",")}{" "}
                    €
                  </p>
                </div>
                <div className="d-flex mt-16 justify-content-between">
                  <p className="subtitle-m bleu700">Total TTC</p>
                  <p className="subtitle-m bleu700">
                    {promo_result?.discount > 0 && promo_result
                      ? promo_result?.totalTTC?.toFixed(2).replace(".", ",")
                      : cart?.totalAvecFraisdelivraison
                          ?.toFixed(2)
                          .replace(".", ",")}{" "}
                    €
                  </p>
                </div>
                <div className="d-grid gap-2">
                  <button
                    className={`cta-primary-m mt-32 w-100 ${
                      selectedTour === "" ||
                      selectedTour === null ||
                      !selectedTour ||
                      isLoading
                        ? "disabled-btn"
                        : ""
                    }`}
                    onClick={handlePaymentClick}
                    disabled={
                      selectedTour === "" ||
                      selectedTour === null ||
                      !selectedTour ||
                      selectedTour === undefined ||
                      isLoading
                    }
                  >
                    {isLoading ? "Traitement..." : "Procéder au paiement"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <p className="body-s mt-32">Panier vide</p>
        </div>
      )}
    </Layout>
  );
};

export default Livraison;