// import Liv from "../assets/imgs/liv.svg";
// import CarteBleu from "../assets/imgs/cb.png";
// import { CgMathPlus } from "react-icons/cg";
// import "../assets/css/panier.scss";
// import ReturnIcon from "../assets/imgs/fi_arrow-left.svg";
// import mapIcon from "../assets/imgs/fi_map-pin-orange.svg";
// import Visa from "../assets/imgs/visaSingle.svg";
// import MasterCard from "../assets/imgs/masterCard.svg";
// import PaymentCards from "../assets/imgs/visa.svg";
// import Paypal from "../assets/imgs/paypal.svg";
// import React, { useEffect, useState } from "react";
// import Services from "../services/global.service";
// import { Button, Card, Collapse } from "react-bootstrap";

// //stripe
// import { Elements,useStripe } from "@stripe/react-stripe-js";
// import AddCardElement from "../components/addCardElement";
// import { Navigate, useNavigate } from "react-router-dom";
// import AddCardElementInPayment from "./addCardElementInPayment";

// const PaymentComp=()=>{
//    const [cart, setCart] = useState();
//   const [promo_result, setPromo_result] = useState([]);

//   const [addBloc, setAddBloc] = useState(false);
//   const [cardForPayment, setCardForPayment] = useState(false);
//   const [paymentMethod, setPaymentMethod] = useState("carte");
//   const [cardDefault, setCardDefault] = useState("");
//   const [clientSecret, setClientSecret] = useState("");
//   const [cards, setCards] = useState([]);
//   const [myTour, setMyTour] = useState();
//   const [clicked, setClicked] = useState(false);
//   const [paymentMethodSelected, setPaymentMethodSelected] = useState(false);// State to track whether payment method is selected
//   const stripe = useStripe();
//   const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
//   const [myAddress, setMyAddress] = useState([]);
//   const [livAdr, setLivAdr] = useState();
//   const navigate=useNavigate()
//   const options = {
//     // passing the client secret obtained from the server
//     clientSecret: clientSecret,
//   };
//   const getDefaultCard=()=>{
//     Services.getAll('getDefaultCard')
//     .then((res)=>{
//       setCardDefault(res?.data?.default_card?.id)
//     })
//     .catch((err)=>{console.log(err)})
//   }
//   const PaymentIntent = () => {
//     Services.create({}, "setup-intent")
//       .then((res) => {
//         setClientSecret(res?.data?.setupIntent);
//       })
//       .catch((err) => {
//         console.log("error", err);
//       });
//   };
//   const getMyCart = () => {
//     Services.getAll("panier")
//       .then((res) => {
//         setCart(res.data?.panier);
//         setPromo_result(res.data?.promo_result)

//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
//   const saveOrder = async () => {
//     try {
//       if(cardForPayment==null || cardForPayment==false){
//       setClicked(true)
//       }
//       const order = {
//         id_panier: cart?.id,
//         id_tourlivraison: localStorage.getItem("tour"),
//         id_adresse_user: livAdr?.id,
//       };

//       if(cardForPayment!==null && cardForPayment!==false){
//         const response = await Services.create({ cart_id: cardForPayment }, 'payments/process');
//         const paymentIntentId = response?.data?.paymentIntent?.client_secret;

//         if (!paymentIntentId) {
//           console.error('Payment intent ID is undefined.');
//           return;
//         }

//         const paymentIntent = await stripe.confirmPaymentIntent(paymentIntentId, {
//           payment_method: cardForPayment,
//           return_url: 'https://www.example.com',
//       });

//         //payement not confirmed
//         if(paymentIntent.error){
//           //add toaster or error msg here
//           console.log('erroe payment')
//           return;
//         }
//         else{
//           await Services.create(order, "commandes/add");
//           navigate('/validation')
//         }
//       }
//       else{
//         setPaymentMethodSelected(false);
//       }

//     } catch (error) {
//       console.error('Error processing payment:', error);
//     }
//   };

//   const getMyCards = () => {
//     Services.getAll("getCards")
//       .then((res) => {
//         setCards(res?.data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
//   const getMyAdresses = () => {
//     Services.getOne(localStorage.getItem("id"), "adresses")
//       .then((res) => {
//         const filteredAddresses = res?.data?.filter((adr) => adr?.type===2);
//         const livAdrfiltred = res?.data?.filter((adr) => adr?.default===1 && adr?.type===1);
//         setMyAddress(filteredAddresses[0]);
//         setLivAdr(livAdrfiltred[0])
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
//   const getTours = () => {
//     Services.getAll(
//       `tour-livraisons/filter-tours?id=${localStorage.getItem("tour")}`
//     )
//       .then((res) => {
//         setMyTour(res?.data[0])
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
//   function plageHoraireLivraison(startDeliveryDateTimeString, endDeliveryDateTimeString) {
//     // Extract time from start and end delivery date times
//     const startTime = startDeliveryDateTimeString.split(" ")[1];
//     const endTime = endDeliveryDateTimeString.split(" ")[1];

//     // Format the time parts
//     const startTimeFormatted = startTime.split(":")[0] + ":" + startTime.split(":")[1];
//     const endTimeFormatted = endTime.split(":")[0] + ":" + endTime.split(":")[1];

//     const deliveryDate = new Date(startDeliveryDateTimeString.split(" ")[0]);
//     const today = new Date();

//     // Check if delivery is scheduled for today
//     if (deliveryDate.toDateString() === today.toDateString()) {
//         return `Livraison aujourd’hui entre ${startTimeFormatted} et ${endTimeFormatted}`;
//     } else {
//         return `Livraison prévue pour ${deliveryDate.toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'long' })} entre ${startTimeFormatted} et ${endTimeFormatted}`;
//     }
// }

//   useEffect(() => {
//     getMyCart();
//     getMyAdresses();
//     getTours();
//     getDefaultCard()
//     PaymentIntent();
//     getMyCards();
//   }, [addBloc]);

// return (
//     <div className="container pb-32">
//         <div className="d-flex align-items-center pt-16">
//           <div>
//             <a href="/livraison">
//               <img src={ReturnIcon} alt="Icon retour" className="mr-8" />
//             </a>{" "}
//           </div>
//           <div>
//             <h4 className="bleu800">Paiement</h4>
//           </div>
//         </div>

//         <div className="row mt-32">
//           <div className="col-md-8">
//             {" "}
//             <div className="white-bg-card text-start">
//               <img src={Liv} alt="clock icon" className="mr-8" />
//               <span className="bleu700 subtitle-s">{myTour && plageHoraireLivraison(myTour?.livraison_heur_debut, myTour?.livraison_heur_fin)}
//               </span>
//             </div>
//             <div className="white-bg-card mt-24">
//               <h6 className="bleu700">Adresse de facturation</h6>

//               <Card.Text className="ref-txt d-flex mt-32 ">
//                 <div className=" mr-8">
//                   <img src={mapIcon} alt="map icon" className="mr-8" />
//                 </div>
//                 <div className="">
//                   <p className="adr-name">{myAddress?.name}</p>
//                   <p className="bleu600 subtitle-s mt-8">
//                     {myAddress?.nom} {myAddress?.prenom}
//                   </p>
//                   <p className="bleu600-body-s mt-8 mb-0">
//                     {myAddress?.adresse}
//                   </p>
//                   <p className="bleu600-body-s my-0">{myAddress?.telephone}</p>
//                 </div>
//               </Card.Text>
//             </div>
//             <div className="white-bg-card mt-16 ">
//               <h6 className="bleu700">Méthode de paiement</h6>

//               <div className="grey-border mt-24">
//                 <div className="d-flex justify-content-between align-items-center">
//                   <div className="form-check">
//                     <input
//                       type="radio"
//                       name="moyen"
//                       value="carte"
//                       id="carte"
//                       className="form-check-input"
//                       defaultChecked
//                       onChange={() => {
//                         setPaymentMethod("carte");
//                       }}
//                     />
//                     <label
//                       htmlFor="carte"
//                       className="form-check-label bleu700 body-m"
//                     >
//                       Carte bancaire
//                     </label>
//                   </div>
//                   <div>
//                     <img src={PaymentCards} alt="payment icon" />
//                   </div>
//                 </div>
//                 {cards?.map((card) => (
//                   <div className="card-grey mt-24">
//                     <div key={card?.card_id} className="form-check text-start ">
//                     <label htmlFor={card?.card_id} className="form-check-label">
//                       {" "}
//                       <input
//                         type="radio"
//                         id={card?.card_id}
//                         name="selectedCard"
//                         value={card?.card_id}
//                         className="form-check-input"
//                         onChange={(e) => {setCardForPayment(e.target.value)}}
//                       />
//                       <div className="d-flex">
//                         <div className="mr-8">
//                           <img
//                             src={card?.brand === "visa" ? Visa : MasterCard}
//                             alt="carte bleu"
//                           />
//                         </div>
//                         <div>
//                           <p className="bleu700-body-s py-0 my-0">****** {card?.last4}</p>
//                         </div>
//                       </div>
//                     </label>
//                   </div>
//                   </div>

//                 ))}
//                 <div className="text-start mt-24">
//                    <Button onClick={()=>{setAddBloc(!addBloc)}} className="link-s"><CgMathPlus /> Ajouter </Button>
//                 </div>

//                 {clientSecret && addBloc && (

//                     <AddCardElementInPayment setAddBloc={setAddBloc} setupIntent={clientSecret} />

//                 )}
//               </div>

//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className="white-bg-card">
//             {!paymentMethodSelected && !cardForPayment && clicked  && (
//                 <div className="alert alert-danger">Veuillez choisir une méthode de paiement ! </div>
//                 )}
//               <h6>Récapitulatif</h6>
//               <div className="d-flex mt-32 justify-content-between">
//                 <p className="bleu700-body-s">Total articles HT</p>
//                 <p className="bleu700-body-s">{ cart?.totalHTProduits?.toFixed(2).replace('.', ',')} €</p>
//               </div>
//               {promo_result?.discount>0 && promo_result && <div className="d-flex mt-16 justify-content-between">
//                   <p className="bleu700-body-s">Réduction</p>
//                   <p className="bleu700-body-s">
//                    - {promo_result?.discount?.toFixed(2).replace('.', ',')} €
//                   </p>
//                 </div>}
//               <div className="d-flex mt-16 justify-content-between">
//                 <p className="bleu700-body-s">Frais de livraisons</p>
//                 <p className="bleu700-body-s">{cart?.FraisLivraison?.toFixed(2).replace('.', ',') ?? 0} €</p>
//               </div>
//               <hr className="mt-16" />
//               <div className="d-flex mt-16 justify-content-between">
//                 <p className="Exergue">Total HT</p>
//                 <p className="Exergue">
//                 {promo_result?.discount>0 && promo_result ? promo_result?.totalHT?.toFixed(2).replace('.', ',') :cart?.totalHT?.toFixed(2).replace('.', ',')} €
//                 </p>
//               </div>
//               <div className="d-flex mt-16 justify-content-between">
//                 <p className="bleu700-body-s ">TVA 20%</p>
//                 <p className="bleu700-body-s ">{cart?.tva20?.toFixed(2).replace('.', ',')}€</p>
//               </div>
//               <div className="d-flex mt-16 justify-content-between">
//                 <p className="subtitle-m bleu700 ">Total TTC</p>
//                 <p className="subtitle-m bleu700">
//                   {promo_result?.discount>0 && promo_result ? promo_result?.totalTTC?.toFixed(2).replace('.', ',') :cart?.totalAvecFraisdelivraison?.toFixed(2).replace('.', ',')} €
//                 </p>
//               </div>

//                 <div className="d-grid gap-2">
//                 <button
//                   className="cta-primary-m mt-32 w-100"
//                   size="lg"
//                   onClick={saveOrder}
//                 >
//                   Confirmer la commande
//                 </button>

//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
// )
// }
// export default PaymentComp;

import Liv from "../assets/imgs/liv.svg";
import CarteBleu from "../assets/imgs/cb.png";
import { CgMathPlus } from "react-icons/cg";
import "../assets/css/panier.scss";
import ReturnIcon from "../assets/imgs/fi_arrow-left.svg";
import mapIcon from "../assets/imgs/fi_map-pin-orange.svg";
import Visa from "../assets/imgs/visaSingle.svg";
import MasterCard from "../assets/imgs/masterCard.svg";
import PaymentCards from "../assets/imgs/visa.svg";
import Paypal from "../assets/imgs/paypal.svg";
import React, { useEffect, useRef, useState } from "react";
import Services from "../services/global.service";
import { Button, Card, Collapse } from "react-bootstrap";

//stripe
import { Elements, useStripe } from "@stripe/react-stripe-js";
import AddCardElement from "../components/addCardElement";
import { Navigate, useNavigate } from "react-router-dom";
import AddCardElementInPayment from "./addCardElementInPayment";

const PaymentComp = () => {
  const [cart, setCart] = useState();
  const [promo_result, setPromo_result] = useState([]);

  const [addBloc, setAddBloc] = useState(false);
  const [cardForPayment, setCardForPayment] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("carte");
  const [cardDefault, setCardDefault] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [cards, setCards] = useState([]);
  const [myTour, setMyTour] = useState();
  const [clicked, setClicked] = useState(false);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(false); // State to track whether payment method is selected
  const stripe = useStripe();
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [myAddress, setMyAddress] = useState([]);
  const [livAdr, setLivAdr] = useState();
  const orderdone = useRef(false);
  const navigate = useNavigate();
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };
  useEffect(() => {
    (async () => {
      if (!stripe) return;
      const urlParams = new URLSearchParams(window.location.search);
      let order = urlParams.get("order");

      const paymentintent = urlParams.get("payment_intent_client_secret");
      if (order && paymentintent && !orderdone.current) {
        order = JSON.parse(order);
        try {
          const payment = await stripe?.retrievePaymentIntent(paymentintent);
          console.log(payment);
          if (payment?.paymentIntent.status == "succeeded") {
            await Services.create(order, "commandes/add");
            navigate("/validation");
            orderdone.current = true;
          } else {
            console.error(
              "Erreur: Le paiement n'a pas abouti. Veuillez réessayer ou contacter le support."
            );
            alert("⚠️ Paiement non confirmé. Veuillez réessayer.");
          }
        } catch (e) {
          console.log(e);
        }
      }
    })();
  }, [stripe]);
  const getDefaultCard = () => {
    Services.getAll("getDefaultCard")
      .then((res) => {
        setCardDefault(res?.data?.default_card?.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const PaymentIntent = () => {
    Services.create({}, "setup-intent")
      .then((res) => {
        setClientSecret(res?.data?.setupIntent);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const getMyCart = () => {
    Services.getAll("panier")
      .then((res) => {
        setCart(res.data?.panier);
        setPromo_result(res.data?.promo_result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const saveOrder = async () => {
    try {
      if (cardForPayment == null || cardForPayment == false) {
        setClicked(true);
      }
      const order = {
        id_panier: cart?.id,
        id_tourlivraison: localStorage.getItem("tour"),
        id_adresse_user: livAdr?.id,
      };

      if (cardForPayment !== null && cardForPayment !== false) {
        const response = await Services.create(
          { cart_id: cardForPayment },
          "payments/process"
        );
        const paymentIntentId = response?.data?.paymentIntent?.client_secret;

        if (!paymentIntentId) {
          console.error("Payment intent ID is undefined.");
          return;
        }

        const paymentIntent = await stripe.confirmPaymentIntent(
          paymentIntentId,
          {
            payment_method: cardForPayment,
            return_url:
              window.location.origin +
              "/paiement?order=" +
              JSON.stringify(order),
          }
        );
        console.log(paymentIntent.paymentIntent);
        if (paymentIntent.error) {
          //add toaster or error msg here
          console.log("erroe payment");
          return;
        }
        if (paymentIntent.paymentIntent.status == "requires_action") {
          const comfirmation = await stripe.handleNextAction({
            clientSecret: response?.data?.paymentIntent?.client_secret,
          });
        } else if (paymentIntent.paymentIntent.status == "succeeded") {
          await Services.create(order, "commandes/add");
          navigate("/validation");
        } else {
          // error
        }

        //payement not confirmed
      } else {
        setPaymentMethodSelected(false);
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  };

  const getMyCards = () => {
    Services.getAll("getCards")
      .then((res) => {
        setCards(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMyAdresses = () => {
    Services.getOne(localStorage.getItem("id"), "adresses")
      .then((res) => {
        const filteredAddresses = res?.data?.filter((adr) => adr?.type === 2);
        const livAdrfiltred = res?.data?.filter(
          (adr) => adr?.default === 1 && adr?.type === 1
        );
        setMyAddress(filteredAddresses[0]);
        setLivAdr(livAdrfiltred[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTours = () => {
    Services.getAll(
      `tour-livraisons/filter-tours?id=${localStorage.getItem("tour")}`
    )
      .then((res) => {
        setMyTour(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function plageHoraireLivraison(
    startDeliveryDateTimeString,
    endDeliveryDateTimeString
  ) {
    // Extract time from start and end delivery date times
    const startTime = startDeliveryDateTimeString.split(" ")[1];
    const endTime = endDeliveryDateTimeString.split(" ")[1];

    // Format the time parts
    const startTimeFormatted =
      startTime.split(":")[0] + ":" + startTime.split(":")[1];
    const endTimeFormatted =
      endTime.split(":")[0] + ":" + endTime.split(":")[1];

    const deliveryDate = new Date(startDeliveryDateTimeString.split(" ")[0]);
    const today = new Date();

    // Check if delivery is scheduled for today
    if (deliveryDate.toDateString() === today.toDateString()) {
      return `Livraison aujourd'hui entre ${startTimeFormatted} et ${endTimeFormatted}`;
    } else {
      return `Livraison prévue pour ${deliveryDate.toLocaleDateString("fr-FR", {
        weekday: "long",
        day: "numeric",
        month: "long",
      })} entre ${startTimeFormatted} et ${endTimeFormatted}`;
    }
  }

  useEffect(() => {
    getMyCart();
    getMyAdresses();
    getTours();
    getDefaultCard();
    PaymentIntent();
    getMyCards();
  }, [addBloc]);
  return (
    <div className="container pb-32">
      <div className="d-flex align-items-center pt-16">
        <div>
          <a href="/livraison">
            <img src={ReturnIcon} alt="Icon retour" className="mr-8" />
          </a>{" "}
        </div>
        <div>
          <h4 className="bleu800">Paiement</h4>
        </div>
      </div>

      <div className="row mt-32">
        <div className="col-md-8">
          {" "}
          <div className="white-bg-card text-start">
            <img src={Liv} alt="clock icon" className="mr-8" />
            <span className="bleu700 subtitle-s">
              {myTour &&
                plageHoraireLivraison(
                  myTour?.livraison_heur_debut,
                  myTour?.livraison_heur_fin
                )}
            </span>
          </div>
          <div className="white-bg-card mt-24">
            <h6 className="bleu700">Adresse de facturation</h6>

            <Card.Text className="ref-txt d-flex mt-32 ">
              <div className=" mr-8">
                <img src={mapIcon} alt="map icon" className="mr-8" />
              </div>
              <div className="">
                <p className="adr-name">{myAddress?.name}</p>
                <p className="bleu600 subtitle-s mt-8">
                  {myAddress?.nom} {myAddress?.prenom}
                </p>
                <p className="bleu600-body-s mt-8 mb-0">{myAddress?.adresse}</p>
                <p className="bleu600-body-s my-0">{myAddress?.telephone}</p>
              </div>
            </Card.Text>
          </div>
          <div className="white-bg-card mt-16 ">
            <h6 className="bleu700">Méthode de paiement</h6>

            <div className="grey-border mt-24">
              <div className="d-flex justify-content-between align-items-center">
                <div className="form-check">
                  <input
                    type="radio"
                    name="moyen"
                    value="carte"
                    id="carte"
                    className="form-check-input"
                    defaultChecked
                    onChange={() => {
                      setPaymentMethod("carte");
                    }}
                  />
                  <label
                    htmlFor="carte"
                    className="form-check-label bleu700 body-m"
                  >
                    Carte bancaire
                  </label>
                </div>
                <div>
                  <img src={PaymentCards} alt="payment icon" />
                </div>
              </div>
              {cards?.map((card) => (
                <div className="card-grey mt-24">
                  <div key={card?.card_id} className="form-check text-start ">
                    <label htmlFor={card?.card_id} className="form-check-label">
                      {" "}
                      <input
                        type="radio"
                        id={card?.card_id}
                        name="selectedCard"
                        value={card?.card_id}
                        className="form-check-input"
                        onChange={(e) => {
                          setCardForPayment(e.target.value);
                        }}
                      />
                      <div className="d-flex">
                        <div className="mr-8">
                          <img
                            src={card?.brand === "visa" ? Visa : MasterCard}
                            alt="carte bleu"
                          />
                        </div>
                        <div>
                          <p className="bleu700-body-s py-0 my-0">
                            ****** {card?.last4}
                          </p>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              ))}
              <div className="text-start mt-24">
                <Button
                  onClick={() => {
                    setAddBloc(!addBloc);
                  }}
                  className="link-s"
                >
                  <CgMathPlus /> Ajouter{" "}
                </Button>
              </div>

              {clientSecret && addBloc && (
                <AddCardElementInPayment
                  setAddBloc={setAddBloc}
                  setupIntent={clientSecret}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="white-bg-card">
            {!paymentMethodSelected && !cardForPayment && clicked && (
              <div className="alert alert-danger">
                Veuillez choisir une méthode de paiement !{" "}
              </div>
            )}
            <h6>Récapitulatif</h6>
            {/* <div className="d-flex mt-32 justify-content-between">
              <p className="bleu700-body-s">Total articles HT</p>
              <p className="bleu700-body-s">
                {cart?.totalHTProduits?.toFixed(2).replace(".", ",")} €
              </p>
            </div> */}
            {/* {promo_result?.discount > 0 && promo_result && (
              <div className="d-flex mt-16 justify-content-between">
                <p className="bleu700-body-s">Réduction</p>
                <p className="bleu700-body-s">
                  - {promo_result?.discount?.toFixed(2).replace(".", ",")} €
                </p>
              </div>
            )}
            <div className="d-flex mt-16 justify-content-between">
              <p className="bleu700-body-s">Frais de livraisons</p>
              <p className="bleu700-body-s">
                {cart?.FraisLivraison?.toFixed(2).replace(".", ",") ?? 0} €
              </p>
            </div> */}

            {promo_result?.discount > 0 &&
            promo_result?.frais_de_port_gratuits === "OUI" ? (
              <>
                <div className="d-flex mt-32 justify-content-between">
                  <p className="bleu700-body-s">Total articles HT</p>
                  <p className="bleu700-body-s">
                    {cart?.totalHTProduits.toFixed(2).replace(".", ",")} €
                  </p>
                </div>
                <div className="d-flex mt-16 justify-content-between">
                  <p className="bleu700-body-s">Frais de livraison</p>
                  <p className="bleu700-body-s">
                    {cart?.FraisLivraison.toFixed(2).replace(".", ",") ?? 0} €
                  </p>
                </div>
                {promo_result?.discount > 0 && promo_result && (
                  <div className="d-flex mt-16 justify-content-between">
                    <p className="bleu700-body-s">Réduction</p>
                    <p className="bleu700-body-s">
                      - {promo_result?.discount?.toFixed(2).replace(".", ",")} €
                    </p>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="d-flex mt-32 justify-content-between">
                  <p className="bleu700-body-s">Total articles HT</p>
                  <p className="bleu700-body-s">
                    {cart?.totalHTProduits.toFixed(2).replace(".", ",")} €
                  </p>
                </div>
                {promo_result?.discount > 0 && promo_result && (
                  <div className="d-flex mt-16 justify-content-between">
                    <p className="bleu700-body-s">Réduction</p>
                    <p className="bleu700-body-s">
                      - {promo_result?.discount?.toFixed(2).replace(".", ",")} €
                    </p>
                  </div>
                )}
                <div className="d-flex mt-16 justify-content-between">
                  <p className="bleu700-body-s">Frais de livraison</p>
                  <p className="bleu700-body-s">
                    {cart?.FraisLivraison.toFixed(2).replace(".", ",") ?? 0} €
                  </p>
                </div>
              </>
            )}
            <hr className="mt-16" />
            <div className="d-flex mt-16 justify-content-between">
              <p className="Exergue">Total HT</p>
              <p className="Exergue">
                {promo_result?.discount > 0 && promo_result
                  ? promo_result?.totalHT?.toFixed(2).replace(".", ",")
                  : cart?.totalHT?.toFixed(2).replace(".", ",")}{" "}
                €
              </p>
            </div>
            <div className="d-flex mt-16 justify-content-between">
              <p className="bleu700-body-s ">TVA 20%</p>
              <p className="bleu700-body-s ">
                {promo_result?.discount > 0 && promo_result
                  ? promo_result.tva20?.toFixed(2).replace(".", ",")
                  : cart?.tva20?.toFixed(2).replace(".", ",")}{" "}
                €{" "}
              </p>
            </div>
            <div className="d-flex mt-16 justify-content-between">
              <p className="subtitle-m bleu700 ">Total TTC</p>
              <p className="subtitle-m bleu700">
                {promo_result?.discount > 0 && promo_result
                  ? promo_result?.totalTTC?.toFixed(2).replace(".", ",")
                  : cart?.totalAvecFraisdelivraison
                      ?.toFixed(2)
                      .replace(".", ",")}{" "}
                €
              </p>
            </div>

            <div className="d-grid gap-2">
              <button
                className="cta-primary-m mt-32 w-100"
                size="lg"
                onClick={saveOrder}
              >
                Confirmer la commande
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentComp;
