import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { Field, Formik } from "formik";
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Services from "../services/global.service";
const UpdateAddress = ({ data, modalAdr, setModalAdr }) => {
  const [address, setAddress] = useState(data?.adresse);
  const [Error, setError] = useState("");
  const [latLong, setLatLong] = useState({
    lat: data?.latitude,
    lng: data?.longitude,
  });

  const handleChangeAddress = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    setError("");
    setAddress(address);
    try{
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
          if (latLng) {
            setLatLong(latLng);
            //  checkAdr(latLng.lat, latLng.lng);
          } else {
            console.error("Error: Unable to get latitude and longitude");
          }
        } catch (error) {
          console.error("Error:", error);
        }
 
    // try {
    //   const results = await geocodeByAddress(address);
    //   const addressComponents = results[0]?.address_components;

    //   // Extract necessary address components
    //   const postalCode = addressComponents.find((component) =>
    //     component?.types?.includes("postal_code")
    //   );
    //   const street = addressComponents.find((component) =>
    //     component?.types?.includes("route")
    //   );
    //   if (postalCode && street) {
    //     const latLng = await getLatLng(results[0]);
    //     if (latLng) {
    //       setLatLong(latLng);
    //       //  checkAdr(latLng.lat, latLng.lng);
    //     } else {
    //       console.error("Error: Unable to get latitude and longitude");
    //     }
    //   } else {
    //     console.error(
    //       "Error: Incomplete address. Missing postal code or street."
    //     );
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };

  const handleClose = () => {
    setModalAdr(false);
  };

  const HandleSaveAdr = async (values) => {
    try {
      const latLongVal = {
        latitude: latLong?.lat,
        longitude: latLong?.lng,
      };
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      if (!address || !latLong?.lat) {
        setError("Adresse requis");
        return;
      }
      // If the type is 2, directly create/update the address without checking zone
      if (data.type === 2) {
        const adr = {
          ...latLongVal,
          ...values,
          adresse: address,
          id: data?.id,
          type: data?.type,
          user: localStorage.getItem("id"),
        };

        Services.updatewithoutID(adr, "adresses")
          .then((res) => {
            setModalAdr(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // Perform zone check for other types
        Services.create(latLongVal, "zones/adresse")

          .then((res) => {
            const adr = {
              ...latLongVal,
              ...values,
              adresse: address,
              id: data?.id,
              type: data?.type,
              user: localStorage.getItem("id"),
            };

            if (res?.data) {
              Services.updatewithoutID(adr, "adresses")
                .then((res) => {
                  setModalAdr(false);
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              setError("Adresse non prise en charge");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      setError("Adresse non prise en charge");
      console.log(err);
    }
  };

  const searchOptions = {
    componentRestrictions: { country: ["fr", "mc"] },
    types: ["geocode", "establishment"],
  };
  useEffect(() => {
    console.log("latLong",latLong)
  }, [latLong]);
  useEffect(() => {
    if (modalAdr) {
      setAddress(data?.adresse || ""); // Réinitialise l'adresse
      setError(""); // Réinitialise l'erreur
      setLatLong({
        lat: data?.latitude,
        lng: data?.longitude,
      }); // Réinitialise les coordonnées
    }
  }, [modalAdr]);
  return (
    <Modal show={modalAdr} onHide={handleClose} className="modal-marion">
      <Modal.Header closeButton>
        <h6>Adresse de livraison</h6>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            name: data?.name,
            gender: data?.gender,
            prenom: data?.prenom,
            nom: data?.nom,
            telephone: data?.telephone,
          }}
          onSubmit={(values) => HandleSaveAdr(values)}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="mt-40">
              <div className="row">
                <div className="col-md-6">
                  {" "}
                  <div className="form-floating mb-3">
                    <Field
                      type="text"
                      name="name"
                      className="form-control"
                      id="floatingInputTitre"
                      placeholder=""
                    />
                    <label htmlFor="floatingInputTitre">
                      Titre de l'adresse (ex: Chantier Nice)
                    </label>
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>

              <div className="row">
                <div className="col-md-6 d-flex">
                  {" "}
                  <div className="form-check mr-16">
                    <Field
                      type="radio"
                      name="gender"
                      value="madame"
                      id="madame"
                      className="form-check-input"
                      defaultChecked
                    />
                    <label
                      htmlFor="madame"
                      className="form-check-label bleu700-body-s"
                    >
                      Madame
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      type="radio"
                      name="gender"
                      value="monsieur"
                      id="monsieur"
                      className="form-check-input"
                    />
                    <label
                      htmlFor="monsieur"
                      className="form-check-label bleu700-body-s"
                    >
                      Monsieur
                    </label>
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>

              <div className="row mt-16">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <Field
                      type="text"
                      name="prenom"
                      className="form-control"
                      id="floatingInputPrenom"
                      placeholder=""
                    />
                    <label htmlFor="floatingInputPrenom">Prénom</label>
                  </div>
                </div>
                <div className="col-md-6">
                  {" "}
                  <div className="form-floating mb-3">
                    <Field
                      type="text"
                      name="nom"
                      className="form-control"
                      id="floatingInputNom"
                      placeholder=""
                    />
                    <label htmlFor="floatingInputNom">Nom</label>
                  </div>
                </div>
              </div>

              <div className="row mt-16">
                <div className="col-md-6">
                  {" "}
                  <div className="form-floating mb-3">
                    <Autocomplete
                      value={address}
                      searchOptions={searchOptions}
                      className=""
                      onChange={handleChangeAddress}
                      onSelect={handleSelect}
                      onPlaceSelected={(place) => {}}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="form-floating mb-3">
                          <input
                            {...getInputProps({
                              placeholder: "",
                              className: `form-control ${
                                Error !== "" ? "danger-input" : ""
                              }`,
                            })}
                            id="floatingAdr"
                          />
                          <label htmlFor="floatingAdr">Adresse</label>
                          <div
                            className={
                              suggestions.length > 0 && `container-autocomplete`
                            }
                          >
                            {loading ? <div>Loading...</div> : null}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#41B6E6"
                                  : "#fff",
                              };
                              return (
                                <div
                                  className="mapItem"
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion?.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </Autocomplete>
                    <div className="text-danger-custom">{Error}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  {" "}
                  <div className="form-floating mb-3">
                    <Field
                      type="text"
                      name="telephone"
                      className="form-control"
                      id="floatingInputTelephone"
                      placeholder=""
                    />
                    <label htmlFor="floatingInputTelephone">
                      Numéro de téléphone
                    </label>
                  </div>
                </div>
              </div>

              <div className="text-end mt-24">
                <button className="cta-secondary-m mr-8" onClick={handleClose}>
                  Annuler
                </button>
                <button type="submit" className="cta-primary-m">
                  Modifier cette adresse
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateAddress;
