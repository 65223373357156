import React, { useState } from "react";
import { ErrorMessage, Formik, useFormikContext } from "formik"; // Import useFormikContext
import Form from "react-bootstrap/Form";
import { useMyContext } from "../context";
import { useLocation, useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import AuthService from "../services/auth.service";
import Services from "./../services/global.service";
import * as Yup from "yup";
import ErrorIcon from "../assets/imgs/fi_alert-triangle.svg";
import axios from "axios";
const validationSchema = Yup.object().shape({
  prenom: Yup.string().required("Prénom est requis"),
  nom: Yup.string().required("Nom est requis"),
  tva: Yup.string().matches(
    /^FR\d{11}$/,"Champ invalide, la TVA doit comporter la mention \"FR\" suivi de 11 chiffres"
  ),
  adresse: Yup.string().required("Adresse de Livraison est requis")
  .test("is-valid-coordinates", "L'adresse est invalide. Veuillez fournir une adresse correcte.", async (value) => {  
    if (!value) return false;  
    try {  
      const results = await geocodeByAddress(value);  
      if (results.length === 0) return false;  
      const location = results[0]?.geometry?.location;  
      return location?.lat && location?.lng;  
    } catch {  
      return false;  
    }  
  }),
  telephone: Yup.string()
    .required("Numéro de téléphone est requis")
    .matches(/^(06|07)\d{8}$/, "Numéro de téléphone invalide"),
});

const FormApropos = () => {
  const location = useLocation();
  const { state } = location;
  const [address, setAddress] = useState("");
  const [latLong, setLatLong] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const { setUserData } = useMyContext();
  const redirectUrl = localStorage.getItem("redirectAfterLogin");

  const handleChangeAddress = (address, setFieldValue) => {
    setAddress(address);
    setFieldValue("adresse", address);
  };
  const handleSelect = async (address, setFieldValue) => {
    setAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLatLong(latLng);
      setFieldValue("adresse", address, true);
    } catch (error) {
      console.error("Erreur lors du géocodage :", error);
    }
  };
  

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };
  const changeDefault = (idAdr) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}addresses/${localStorage.getItem(
          "id"
        )}/update-default-livraison-address/${idAdr}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        window.location.href = "/";
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const createAdr = () => {
    const adr = {
      name: "Mon adresse",
      nom: localStorage.getItem("nom") ?? "",
      prenom: localStorage.getItem("prenom") ?? "",
      latitude: localStorage.getItem("lat"),
      longitude: localStorage.getItem("lng"),
      adresse: localStorage.getItem("adr"),
      user: localStorage.getItem("id"),
      type: 1,
      default: 1,
    };
    // Services.create(adr,'adresses/create')
    axios
      .post(process.env.REACT_APP_BASE_URL + "adresses/create", adr, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        changeDefault(res?.data?.adresse?.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (values) => {
    try {
      const email = state && state?.email;
      const password = state && state?.password;

      const data = {
        ...values,
        email,
        password,
        adresse: address,
        employee_commande: isChecked,
        latitude: latLong?.lat,
        longitude: latLong?.lng,
      };

      Services.create(data, "register")
        .then((res) => {
          localStorage.setItem("token", res?.data?.data?.token);
          localStorage.setItem("id", res?.data?.data?.user?.id);
          localStorage.setItem("role", res?.data?.data?.role);
          localStorage.setItem("nom", res?.data?.data?.user?.nom);
          localStorage.setItem("prenom", res?.data?.data?.user?.prenom);
          setUserData(res?.data?.data?.user);
          if (localStorage.getItem("lat") && localStorage.getItem("lng")) {
            createAdr();
            if (redirectUrl) {
              window.location.href = redirectUrl;
            }
          } else {
            window.location.href = "/";
          }

          // localStorage.setItem("adr",res.data?.data?.adresse?.adresse)

          // Reload the page
          //  window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
      // Perform your form submission logic here
      // await Services.create({ ...data, email, password }, 'register');

      // Reset form fields
      setAddress("");
      setLatLong({});
      setIsChecked(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const searchOptions = {
    componentRestrictions: { country: ["fr","mc"] },
  types: ["geocode","establishment"],
  };
  return (
    <div className="min-vh-100">
      <Formik
        initialValues={{
          prenom: "",
          nom: "",
          telephone: "",
          societe: "",
          siret: "",
          tva: "",
          adresse: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form
            onSubmit={handleSubmit}
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
          >
            <div className="row">
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="prenom"
                    onChange={handleChange}
                    value={values?.prenom}
                    id="floatingPrenom"
                    className={`form-control ${
                      touched.prenom && errors.prenom ? "danger-input" : ""
                    }`}
                    placeholder=""
                  />
                  <label htmlFor="floatingPrenom">Prénom</label>
                  {touched.prenom && errors.prenom && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger-custom">
                        {errors.prenom}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="nom"
                    id="floatingnom"
                    value={values?.nom}
                    onChange={handleChange}
                    className={`form-control ${
                      touched.nom && errors.nom ? "danger-input" : ""
                    }`}
                    placeholder=""
                  />
                  <label htmlFor="floatingnom">Nom</label>
                  {touched.nom && errors.nom && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger-custom">{errors.nom}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-6">
                <PlacesAutocomplete
                  value={address}
                  onChange={(newValue) =>
                    handleChangeAddress(newValue, setFieldValue)
                  }
               
                  onSelect={(selected) => handleSelect(selected, setFieldValue)}
                  searchOptions={searchOptions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="form-floating mb-3">
                      <input
                        {...getInputProps({
                          placeholder: "",
                          className: `form-control ${
                            touched.adresse && errors.adresse
                              ? "danger-input"
                              : ""
                          }`,
                        })}
                        id="floatingAdr"
                      />
                      <label htmlFor="floatingAdr">Adresse</label>
                      <div
                        className={
                          suggestions.length > 0 && `container-autocomplete`
                        }
                      >
                        {loading ? <div>Loading...</div> : null}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#41B6E6"
                              : "#fff",
                          };
                          return (
                            <div
                              className="mapItem"
                              {...getSuggestionItemProps(suggestion, {
                                style,
                              })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

                {touched.adresse && errors.adresse && (
                  <div className="text-start mt-8 d-flex">
                    <img src={ErrorIcon} className="mr-8" alt="error icon" />
                    <span className="text-danger-custom">{errors.adresse}</span>
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="tel"
                    name="telephone"
                    value={values?.telephone}
                    onChange={handleChange}
                    placeholder=""
                    className={`form-control ${
                      touched.telephone && errors.telephone
                        ? "danger-input"
                        : ""
                    }`}
                  />
                  <label htmlFor="floatingnom">Numéro de téléphone </label>
                  {touched.telephone && errors.telephone && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger-custom">
                        {errors.telephone}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="societe"
                    value={values?.societe}
                    className={`form-control ${
                      touched.societe && errors.societe ? "danger-input" : ""
                    }`}
                    onChange={handleChange}
                    placeholder=""
                  />

                  <label htmlFor="floatingnom">
                    Nom de la société (facultatif)
                  </label>
                </div>
                <ErrorMessage name="societe" />
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="tva"
                    value={values?.tva}
                    className={`form-control ${
                      touched.tva && errors.tva ? "danger-input" : ""
                    }`}
                    onChange={handleChange}
                    placeholder=""
                  />

                  <label htmlFor="floatingnom">TVA (facultatif)</label>
                  {touched.tva && errors.tva && (
                    <div className="text-start mt-8 d-flex">
                      <img src={ErrorIcon} className="mr-8" alt="error icon" />
                      <span className="text-danger-custom">
                        {errors.tva}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="siret"
                    value={values?.siret}
                    className={`form-control ${
                      touched.siret && errors.siret ? "danger-input" : ""
                    }`}
                    onChange={handleChange}
                    placeholder=""
                  />

                  <label htmlFor="floatingnom">SIREN (facultatif)</label>
                </div>
                {touched.siret && errors.siret && (
                  <div className="text-start mt-8 d-flex">
                    <img src={ErrorIcon} className="mr-8" alt="error icon" />
                    <span className="text-danger-custom">{errors.siret}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="row mt-20">
              <div className="col">
              </div>
            </div>
            <div className="row mt-32">
              <div className="col-md-6"></div>
              <div className="col-md-6 text-end">
                <button className="cta-primary-m w-100" type="submit">
                  Créer mon compte
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormApropos;
